import React, { useState } from "react"
import NoSidebarLayout from "../layout/NoSidebarLayout"
import styled from "styled-components"
import { RealButton } from "../components/ButtonElement"
import {contactBanner} from "../images/"
import SEO from "../components/seo"

const sidebar = [
  {
    to: "aboutUs",
    title: "About Us",
  },
  {
    to: "ourStory",
    title: "Our Story",
  },
  {
    to: "ourTeam",
    title: "Our Team",
  },
  {
    to: "ourAchievements",
    title: "Our Achievements",
  },
]

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px 30px 10px;
`

export default function Contact() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    number: "",
    department: "Guardianship",
    message: "",
    success: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          number: "",
          department: "Guardianship",
          message: "",
          success: true,
        })

      })
      .catch(error => alert(error))
    e.preventDefault()
  }

  return (
        <NoSidebarLayout sidebar={sidebar} pageTitle="Contact Us" background={contactBanner} pageDescription="Drop us a message or contact us on social media">
          <SEO title="Contact | Guardianship | Schools | Homestay | Agents" description="Study Links works alongside the best schools & other educational institutions in the UK. Get in touch with us for more information."/>
        {formState.success && <div class="alert alert-success" role="alert">
          Your message has been submitted
        </div>}
          <form
            onSubmit={handleSubmit}
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="https://getform.io/f/808359fd-2c9e-40cc-a7cd-9de276df22ca"
          >
            <input type="hidden" name="form-name" value="contact" />
            <FormContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="John Smith"
                    name="name"
                    onChange={handleChange}
                    value={formState.name}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput2">Email address</label>
                  <input
                    required
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput2"
                    placeholder="name@example.com"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput3">Phone number</label>
                  <input
                    required
                    type="tel"
                    class="form-control"
                    id="exampleFormControlInput3"
                    placeholder="e.g. +44 (0)1274 679710"
                    name="number"
                    onChange={handleChange}
                    value={formState.number}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlSelect1">
                    Please select department
                  </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    value={formState.department}
                    onChange={handleChange}
                    name="department"
                  >
                    <option value="Guardianship">Guardianship</option>
                    <option value="Homestay">Homestay</option>
                    <option value="Finance">Finance</option>
                    <option value="StudentService">Student Services</option>
                    <option value="GeneralEnquiries">General enquiries</option>
                  </select>
                </div>
              </FormSubContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Your Message:</label>
                  <textarea
                    required
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    name="message"
                    onChange={handleChange}
                    value={formState.message}
                  ></textarea>
                </div>
                <RealButton type="submit">Submit</RealButton>
              </FormSubContainer>
            </FormContainer>
          </form>
        </NoSidebarLayout>
  )
}
